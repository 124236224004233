import { AfterContentInit, AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ContentService } from '../service/content.service';
import { SiteInfoService } from '../service/site-info.service';



@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.css']
})
export class ArticleComponent implements OnInit, AfterViewInit{

  @ViewChild('img-art') img: ElementRef;

  contents = [];
  articleContent = [];

  themeStyle = '';
  themeInfo: any;
  constructor(private mainContent: ContentService, private siteInfo: SiteInfoService) { }

  ngOnInit(): void {
    const image = document.getElementsByTagName('img') as HTMLCollectionOf<HTMLElement>;
    console.log(image);

    window.scroll(0, 0);
    this.contents = this.mainContent.getPageContent();
    console.log(this.contents);
    const contLength = this.contents.length;

    for (let i = 0; i < contLength; i++) {
      if (this.contents[i].section_type.code === 'Article') {
        this.articleContent.push(this.contents[i].contents);
      }
    }
    this.articleContent = this.articleContent[0];
    console.log(this.articleContent);

    this.getSiteInfo();
  }

  getSiteInfo() {
    this.siteInfo.getSiteInfo()
      .subscribe(resdata => {
        this.themeInfo = resdata;
        this.themeStyle = this.themeInfo.site_info.theme.code;
      });
  }

  ngAfterViewInit() {
    const image = document.getElementsByTagName('img') as HTMLCollectionOf<HTMLElement>;

    // image.item(2).setAttribute('id', 'img-art');
    console.log(image);

  }
}
