import { Component, Input, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContentService } from '../service/content.service';
import { HeaderService } from '../service/header.service';
import { SiteInfoService } from '../service/site-info.service';

@Component({
  selector: 'app-main-content',
  templateUrl: './main-content.component.html',
  styleUrls: ['./main-content.component.css']
})
export class MainContentComponent implements OnInit {

  pageData: any;
  // contentName = [];
  pageSections = [];
  currentPage = '';
  pageTitle = '';

  paramObj = [];
  pages = [];
  numkey: number;

  headerLinks = [];
  levelTwoData: any;
  levelThreeData: any;
  levelFourData: any;
  levelFiveData: any;
  urlData = [];
  viewUrlData = [];
  url = '';
  deepLinkSection = [];
  catLink = '';

  levelTwo = [];
  levelThree = [];
  levelFour = [];
  levelFive = [];

  // for impian
  themeStyle: any;
  // end

  constructor(
    private route: ActivatedRoute,
    private serviceCont: ContentService,
    private viewUrl: HeaderService,
    private siteInfo: SiteInfoService) {}

  ngOnInit(): void {
    // window.addEventListener('scroll', this.scroll, true);
    window.scroll(0, 0);
    this.route.paramMap
      .subscribe(params => {
        this.viewUrlData = [];
        this.viewUrlData.push(params);
        // console.log(this.viewUrlData);
        const urlCheck = Object.keys(this.viewUrlData[0].params).length;
        switch (urlCheck){
          case 1: {
            const name = params.get('name');
            this.currentPage = params.get('name');
            localStorage.setItem(this.currentPage, name);
            this.getHeadrContent(name);
            break;
          }
          case 2 : {
            // const innerPage = '/' + this.viewUrlData[0].params.name + '/' + this.viewUrlData[0].params.item;
            // console.log(this.viewUrlData[0].params);
            this.changeContent(this.viewUrlData[0].params);
            break;
          }
          case 3: {
            // console.log(this.viewUrlData[0].params);
            this.changeContent(this.viewUrlData[0].params);
            break;
          }
        }
      });

    this.getTheme();
  }

  scroll = (): void => {
    if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
      document.getElementById('main-nav').style.transition = 'all 5s';
      document.getElementById('main-nav').style.height = '30px 10px';
      document.getElementById('logo').style.height = '40px';
    } else {
      document.getElementById('main-nav').style.transition = 'all 5s';
      document.getElementById('main-nav').style.height = '80px 10px';
      document.getElementById('logo').style.height = '50px';
    }
  }

  getHeadrContent(name) {
    this.viewUrl.getHeader()
          .subscribe(resdata => {
            this.headerLinks = resdata.header.links;
            console.log(this.headerLinks);

            if (name === 'home') {
              this.url = '/';
            } else {
              this.url = '/' + name;
            }

            for (const [i, links] of this.headerLinks.entries()) {
              this.urlData.push(links.url);
            }
            // console.log(this.url);
            // console.log(this.urlData);

            // level 1 link data
            const result = this.urlData.findIndex( e => e === this.url);
            try {
              if (result.toString()) {
                this.changeContent(this.headerLinks[result].view_url);
              }
            } catch (error) {
              const numKey = Object.keys(this.viewUrlData[0].params).length;
              console.log(numKey);
              switch (numKey) {
                case 1: {
                  // get level two links
                  this.getSubLinks(this.headerLinks);
                  // get view url
                  const innerLink = '/' + name;
                  const innData = this.getDataUrl(innerLink);
                  console.log(innerLink);
                  // console.log(innData.view_url);
                  if (innData !== undefined) {
                    if (innData.link_type === 'page') {
                      this.changeContent(innData.view_url);
                    }
                    if (innData.link_type === 'product') {
                      this.changeContent(innData.view_url);
                    }
                    if (innData.link_type === 'category') {
                      this.serviceCont.pageCategories(innData.view_url)
                        .subscribe(data => {
                          let innLinkData: any;
                          let levTwoLink = [];

                          innLinkData = data;
                          console.log(innLinkData);
                          levTwoLink = innLinkData.categories;
                          console.log(levTwoLink);
                          console.log(innData.name);
                          // tslint:disable-next-line:prefer-for-of
                          for (let i = 0; i < levTwoLink.length; i++) {
                            if (levTwoLink[i].label === innData.name) {
                              console.log(levTwoLink[i].product_view_url);
                              this.catLink = levTwoLink[i].product_view_url;
                              this.changeContent(levTwoLink[i].product_view_url);
                              this.pageTitle = innData.name;
                            } else {
                              this.pageTitle = innData.name;
                              this.changeContent(innData.view_url);
                            }
                          }

                        });
                    }
                  } else {
                    this.pageSections = [{code: '404', name: '404'}];
                  }
                  break;
                }
                case 2: {
                  this.deepLinkSection.push({name: this.viewUrlData[0].params.name, item: this.viewUrlData[0].params.item });
                  this.changeContent(this.deepLinkSection);
                  break;
                }
              }
              // this.pageSections = [{code: '404', name: '404'}];
            }
          });
  }

  changeContent(page) {
    console.log(page);
    console.log(typeof(page));
    if (typeof(page) === 'string') {
      this.serviceCont.pageContents(page)
      .subscribe(resdata => {
        console.log(resdata);
        if (typeof(resdata) !== 'undefined') {
          this.pageData = resdata;
          console.log(this.pageData);
          // inner links
          const resultPage = this.pageData.hasOwnProperty('page');
          if (resultPage) {
            this.pageSections = [];
            console.log(this.pageData);
            // this.pageSections = this.pageData.page.sections;
            // console.log(this.pageSections);

            this.getSectionType(this.pageData.page.sections);

            this.serviceCont.setPageContent(this.pageData.page.sections); // send page data to content service
            this.pageTitle = this.pageData.page.title;
          }

          const resultCategory = this.pageData.hasOwnProperty('categories');
          if (resultCategory) {
            // console.log(resultCategory);
            this.pageSections = [{code: 'Category List', name: 'Category List'}];
          }
          const resultArticle = this.pageData.hasOwnProperty('article');
          if (resultArticle) {
            // console.log(resultCategory);
            this.pageSections = [{code: 'Article View', name: 'Article View'}];
          }

          const product = this.pageData.hasOwnProperty('product');
          if (product) {
            // this.serviceCont.pageContents(dataUrl)
            this.pageSections = [{code: 'Inner Product', name: 'Inner Product'}];
            console.log(this.pageData);
            this.serviceCont.setPageContent(this.pageData);
            this.pageTitle = '';
          }

          const resultProduct = this.pageData.hasOwnProperty('products');
          if (resultProduct) {
            // console.log(resultProduct);
            this.pageSections = [{code: 'Product Category', name: 'Product Category'}];
            // console.log(this.pageData.products);
            // this.serviceCont.setPageContent(this.pageData[0].products);
          }
        } else {
          this.pageSections = [{code: '404', name: '404'}];
        }
      });
    } else {
      let dataUrl = '';
      switch (Object.keys(page).length) {
        case 2: {
          dataUrl = '/' + page.name + '/' + page.item;
          break;
        }
        case 3: {
          dataUrl = '/' + page.name + '/' + page.item + '/' + page.id;
          break;
        }
      }
      // console.log(dataUrl);
      this.serviceCont.pageContents(dataUrl)
        .subscribe(resdata => {
          this.pageData = resdata;
          console.log(resdata);
          this.serviceCont.setPageContent(this.pageData);
          if (Object.keys(page).length === 2) {
            if (page.name === 'articles') {
              this.pageSections = [{code: 'Article View', name: 'Article View'}];
            } else {
              this.pageSections = [{code: 'View Product', name: 'View Product'}];
            }
          } else {
            // console.log(page.name);
            this.pageSections = [{code: 'Category List', name: 'Category List'}];
            this.pageTitle = 'Category List';
          }
        }, error => {
          this.pageSections = [{code: '404', name: '404'}];
        });
    }
  }

  getTheme() {
    this.siteInfo.getSiteInfo()
      .subscribe(resdata => {
        this.themeStyle = resdata;
        this.themeStyle = this.themeStyle.site_info.theme.code;
      });
  }

  getSubLinks(data: any) {
    // console.log(data);
    this.levelTwo = [];
    this.levelThree = [];
    this.levelFour = [];
    // tslint:disable-next-line:prefer-for-of
    for (let  i = 0; i < data.length; i++) {
      if (data[i].level_two_links.length > 0) {
        this.levelTwo.push(data[i].level_two_links);
      }
    }
    this.getLevelThreeLink();
    // console.log(this.levelTwo[1]);
  }

  getLevelThreeLink() {
    // console.log(this.levelTwo);
    // tslint:disable-next-line:prefer-for-of
    for (let  i = 0; i < this.levelTwo.length; i++) {
      // tslint:disable-next-line:prefer-for-of
      for (let x = 0; x < this.levelTwo[i].length; x++) {
        if (this.levelTwo[i][x].level_three_links.length > 0) {
          this.levelThree.push(this.levelTwo[i][x].level_three_links);
        }
      }
    }
    this.levelTwo.push(this.levelThree);
    this.getLevelFourLink();
  }

  getLevelFourLink() {
    // tslint:disable-next-line:prefer-for-of
    for (let  i = 0; i < this.levelThree.length; i++) {
      // tslint:disable-next-line:prefer-for-of
      for (let x = 0; x < this.levelThree[i].length; x++) {
        if (this.levelThree[i][x].level_four_links.length > 0) {
          this.levelFour.push(this.levelThree[i][x].level_four_links);
        }
      }
    }
    this.levelTwo.push(this.levelFour);
    // this.getLevelFiveLink();
    console.log(this.levelTwo);
  }

  // getLevelFiveLink() {
  //   // tslint:disable-next-line:prefer-for-of
  //   for (let  i = 0; i < this.levelTwo.length; i++) {
  //     // tslint:disable-next-line:prefer-for-of
  //     for (let x = 0; x < this.levelTwo[i].length; x++) {
  //       if (this.levelTwo[i][x].level_five_links.length > 0) {
  //         this.levelFive.push(this.levelTwo[i][x].level_five_links);
  //       }
  //     }
  //   }
  //   this.levelTwo.push(this.levelFive[0]);
  //   console.log(this.levelTwo);
  // }

  getDataUrl(name = '') {
    // console.log(this.levelTwo[4][0][0]);
    console.log(name);
    // tslint:disable-next-line:prefer-for-of
    for (let  i = 0; i < this.levelTwo.length; i++) {
      // console.log(this.levelTwo[i]);
      // tslint:disable-next-line:prefer-for-of
      for (let x = 0; x < this.levelTwo[i].length; x++) {
        if (this.levelTwo[i][x].url === name) {
          console.log(this.levelTwo[i][x].url);
          return {name: this.levelTwo[i][x].name, link_type: this.levelTwo[i][x].link_type, view_url: this.levelTwo[i][x].view_url};
        }else {
          // console.log(this.levelTwo[i][x].url);
          // const result = this.getLevelFourDataUrl(name);
          // return result;
          // tslint:disable-next-line:prefer-for-of
          for (let y = 0; y < this.levelTwo[i][x].length; y++) {
            if (this.levelTwo[i][x][y].url === name) {
              console.log(this.levelTwo[i][x].url);
              // tslint:disable-next-line:max-line-length
              return {name: this.levelTwo[i][x][y].name, link_type: this.levelTwo[i][x][y].link_type, view_url: this.levelTwo[i][x][y].view_url};
            }
          }
        }
      }
    }
  }

  getSectionType(data: any) {
    console.log(data.length);
    if (data.length > 0) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < data.length; i++) {
        switch (data.length) {
          case 1: {
            this.pageSections = [{code: data[i].section_type.code, name: data[i].section_type.name}];
            console.log(this.pageSections);
            break;
          }
          default: {
            this.pageSections = data;
          }
        }
      }
    }
  }
}
