import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { ContentService } from '../service/content.service';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit, AfterViewInit {

  caroData: any;
  caroSection = [];
  caroContents = [];
  caroExtracted = [];
  currentPage = '';

  themeStyle = '';
  element: any;
  count: any;

  constructor(
    private caroContent: ContentService) { }

  ngOnInit(): void {
    this.getCarro();
    this.themeStyle = this.caroContent.getTheme();
    // console.log(this.themeStyle);
  }

  getCarro() {
    this.caroData = this.caroContent.getPageContent();
    this.extractSection(this.caroData);
    console.log(this.caroData);
  }

  extractSection(data: any) {
    console.log(data);
    for (let i = 0; i < data.length; i++) {
      if (data[i].section_type.code === 'Carousel') {
        this.caroContents.push(data[i]);
        this.caroExtracted = this.caroContents[i].contents;
      }
    }
  }

  ngAfterViewInit() {
    const prev = document.getElementsByClassName('carousel-control-prev-icon') as HTMLCollectionOf<HTMLElement>;
    const next = document.getElementsByClassName('carousel-control-next-icon') as HTMLCollectionOf<HTMLElement>;
    const indicator = document.getElementsByClassName('carousel-indicators') as HTMLCollectionOf<HTMLElement>;

    if (this.caroExtracted.length === 1) {
      prev[0].style.display = 'none';
      next[0].style.display = 'none';
      indicator[0].style.display = 'none';
    }
  }
}
