import { AfterContentInit, AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContentService } from '../service/content.service';
import { SiteInfoService } from '../service/site-info.service';

@Component({
  selector: 'app-article-view',
  templateUrl: './article-view.component.html',
  styleUrls: ['./article-view.component.css']
})
export class ArticleViewComponent implements OnInit, AfterViewInit{

  artViewCont: any;
  articleBody = '';
  articleTitle = '';

  themeStyle = '';
  siteInfo: any;

  constructor(private serviceContent: ContentService, private siteInfoService: SiteInfoService, private router: Router) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    if (!localStorage.getItem('url')) {
      localStorage.setItem('url', this.router.url);
      location.reload();
    } else {
      localStorage.removeItem('url');
    }
    this.artViewCont = this.serviceContent.getPageContent();
    console.log(this.artViewCont);
    this.articleTitle = this.artViewCont.article.title;
    this.articleBody = this.artViewCont.article.body;

    this.getSiteInfo();
  }

  getSiteInfo() {
    this.siteInfoService.getSiteInfo()
      .subscribe(resdata => {
        this.siteInfo = resdata;
        this.themeStyle = this.siteInfo.site_info.theme.code;
        // if (this.themeStyle === 'impian_emas') {
        //   document.body.style.backgroundImage = 'url(../../assets/image/other.jpg)';
        //   document.body.style.backgroundSize = 'cover';
        //   document.body.style.backgroundColor = '';
        // }
      });
  }

  ngAfterViewInit() {
    const image = document.getElementsByTagName('img') as HTMLCollectionOf<HTMLElement>;
    image.item(0).removeAttribute('width');
    image.item(0).removeAttribute('height');
    image.item(0).setAttribute('id', 'imp-img');
    console.log(image.item(0));
  }
}
