import { Component, OnInit } from '@angular/core';
import { ContentService } from '../service/content.service';
import { SiteInfoService } from '../service/site-info.service';
import { GlobalConstants } from '../common/global-constants';

@Component({
  selector: 'app-article-list',
  templateUrl: './article-list.component.html',
  styleUrls: ['./article-list.component.css']
})
export class ArticleListComponent implements OnInit {

  artContent: any;
  articles = [];
  getArticle = [];

  articleDataForTesting = [];

  page = 0;
  count = 0;
  tableSize = 0;
  tableSizes = [10, 1];

  themeStyle = '';
  siteInfoData: any;

  constructor(private artList: ContentService, private siteInfoService: SiteInfoService) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.getArticles();
    this.getTheme();
  }

  getArticles() {
    this.artContent = this.artList.getPageContent();
    for (const article of this.artContent) {
      if (article.name === 'Article Listing' || article.section_type.code === 'ItemList') {
        this.artContent = article;
        this.artList.pageContents(this.artContent.view_url)
         .subscribe(resdata => {
          console.log(resdata);
          this.getArticle.push(resdata);
          this.articles = this.getArticle[0].articles;
          this.tableSize = this.getArticle[0].paginator.perPage;
          this.count = this.getArticle[0].paginator.count;
          this.page = this.getArticle[0].paginator.page;
         });
      }
    }
  }

  onTableDataChange(event: any){
    window.scroll(0, 0);
    this.artContent = this.artList.paginator(
      '/articles/index.php?page=' + event
      )
      .subscribe(data => {
        console.log(data);
        this.getArticle = [];
        this.getArticle.push(data);
        this.articles = this.getArticle[0].articles;
        this.tableSize = this.getArticle[0].paginator.perPage;
        this.count = this.getArticle[0].paginator.count;
        this.page = this.getArticle[0].paginator.page;
        this.page = event;
        this.articles = this.articles;
        console.log(this.getArticle);
      });
    // alert(event);
  }

  onTableSizeChange(event: any): void {
    this.tableSize = event.target.value;
    this.page = 1;
    this.articles  = this.articles;
  }

  sort(data) {
    // console.log(data);
    this.articles.sort((a, b) => {
      const fa = a.title.toLowerCase();
      const fb = b.title.toLowerCase();

      switch (data){
        case 'DESC': {
          if (fa > fb) {
            return -1;
          }
          if (fa < fb) {
            return 1;
          }
          break;
        }
        case 'ASC': {
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            break;
        }
        default: {
          return 0;
        }
      }
    });
  }

  getTheme() {
    this.siteInfoService.getSiteInfo()
      .subscribe(resdata => {
        this.siteInfoData = resdata;
        this.themeStyle = this.siteInfoData.site_info.theme.code;
        console.log(this.themeStyle);
      });
  }

  paginator(url: string) {
  }

}
